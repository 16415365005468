
import { get } from "lodash-es";
import firebase from "firebase";
import { encodeString, fixBangladeshPhoneNumbers, isValidPhoneNumber } from "../util/misc";

const database          = firebase.database();

//these are account specific scripts to fix certain db things that were not done properly
class LegancyOneTimeScripts {

    run(db){
        this.fixStudentParentPhoneMapper(db);
    }

    fixStudentParentPhoneMapper(db){

        if (get(db, ['LegacyScriptFixer', 'fixStudentParentPhoneMapper'], false) === true){
            return true;
        }

        console.log('Running fixStudentParentPhoneMapper');

        let userProff = get(db, ['PublicInfo', 'UserProffession'], undefined);

        if (!userProff){
            return;
        }

        if (userProff === 'Teacher'){
            let userClass = get(db, ['UserClass'], {});
    
            for (let grade in userClass){
                for (let subject in  get(userClass, [grade], {})){
                    for (let batch in get(userClass, [grade, subject, 'Streams'], {})){
                        let acceptedStudents = get(userClass, [grade, subject, 'Streams', batch, 'AcceptedStudents'], {});
    
                        for (let oneStudent in acceptedStudents){
                            let {Name, Phone, parent1phone, parent2phone} = acceptedStudents[oneStudent];
    
                            if (isValidPhoneNumber(fixBangladeshPhoneNumbers(Phone))){
                                if (parent1phone && isValidPhoneNumber(fixBangladeshPhoneNumbers(parent1phone))){
                                    database.ref(`PARENT-STUDENT-MAPPER/${(fixBangladeshPhoneNumbers(parent1phone))}/`).update({
                                        [fixBangladeshPhoneNumbers(Phone)]: encodeString(Name)
                                    })
                                }
                            
                                if (parent2phone && isValidPhoneNumber(fixBangladeshPhoneNumbers(parent2phone))){
                                    database.ref(`PARENT-STUDENT-MAPPER/${(fixBangladeshPhoneNumbers(parent2phone))}/`).update({
                                        [fixBangladeshPhoneNumbers(Phone)]: encodeString(Name)
                                    })
                                }
                            }
    
                    }
                }
                }
            }

        }

        if (userProff === 'Admin'){
            let teachers = get(db, ['VolatileLocalData', 'Admined'], {});
            for (let oneTUID in teachers){
                let userClass = get(db, ['VolatileLocalData', 'Admined', oneTUID, 'UserClass'], {});
        
                for (let grade in userClass){
                    for (let subject in  get(userClass, [grade], {})){
                        for (let batch in get(userClass, [grade, subject, 'Streams'], {})){
                            let acceptedStudents = get(userClass, [grade, subject, 'Streams', batch, 'AcceptedStudents'], {});
        
                            for (let oneStudent in acceptedStudents){
                                let {Name, Phone, parent1phone, parent2phone} = acceptedStudents[oneStudent];
        
                                if (isValidPhoneNumber(fixBangladeshPhoneNumbers(Phone))){
                                    if (parent1phone && isValidPhoneNumber(fixBangladeshPhoneNumbers(parent1phone))){
                                        database.ref(`PARENT-STUDENT-MAPPER/${(fixBangladeshPhoneNumbers(parent1phone))}/`).update({
                                            [fixBangladeshPhoneNumbers(Phone)]: encodeString(Name)
                                        })
                                    }
                                
                                    if (parent2phone && isValidPhoneNumber(fixBangladeshPhoneNumbers(parent2phone))){
                                        database.ref(`PARENT-STUDENT-MAPPER/${(fixBangladeshPhoneNumbers(parent2phone))}/`).update({
                                            [fixBangladeshPhoneNumbers(Phone)]: encodeString(Name)
                                        })
                                    }
                                }
        
                        }
                    }
                    }
                }
            }
        }

        database.ref(`USERS/${db['UID']}/LegacyScriptFixer`).update({
            'fixStudentParentPhoneMapper': true
        })
    } 

}


export default new LegancyOneTimeScripts()